

.recommended-heading {
  font-family: 'Arial', sans-serif;
  text-align: center;
  margin-bottom: 20px;
  color: #fff;
}

.pimg{
 max-height:180px ;
}

.rp {
  font-family: 'Arial', sans-serif;
  background-color: #ccc5b9;
  padding: 20px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}



.grid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.grid__item {
  max-width: 300px;
  width: 100%;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-10px);
}

.card img {
  width: 100%;
  height: auto;
  display: block;
}

.card__content {
  padding: 20px;
}

.card__header {
  font-size: 1.5rem;
  margin: 0 0 10px;
  color: #333;
}

.card__text {
  font-size: 1rem;
  margin: 0 0 20px;
  color: #666;
}

.card__btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.card__btn span {
  display: inline-block;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.card__btn:hover {
  background-color: #0056b3;
}

.card__btn:hover span {
  transform: translateX(5px);
}



