
.topnav {
  background-color: #f00;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px; 
  margin-top: 0; 
  padding: 0 20px; 
}

img {
  height: 40px; 
  width: auto; 
}

.klogo {
  margin-right: 0px; 
}

.kname {
  margin-left: 5px; 
  font-size: 18px;
}


.Karnival,
.Kotak {
  display: flex;
  align-items: center;
  color: #fff;
  font-family: Roboto, serif;
  font-weight: 700;
}

/*new nav css*/

/* General nav styling */
